


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseTable from '../../../components/BaseTable.vue';
import AssignExceptionsToModule from './AssignExceptionsToModule.vue';
import {
  ResourceTypes,
  Role,
  RolesAndExceptionsResponse
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import { Group } from '../../../store/modules/admin/types/admin.types';

@Component({
  components: {
    AssignExceptionsToModule,
    BaseTable
  }
})
export default class AssignExceptionsComponent extends Vue {
  @Prop({ default: ResourceTypes.USER }) public resourceType!: ResourceTypes;
  @Prop() public moduleExceptions!: Role;
  @Prop({ default: false }) isInstances!: boolean;
  @Prop({ default: false }) isManageExceptions!: boolean;
  @Prop() public existingPermissions!: RolesAndExceptionsResponse;
  @Prop() public selectedInstance!: Group;

  public moduleExceptionsTree: Role = JSON.parse(
    JSON.stringify(this.moduleExceptions)
  );
  public assignExceptionsToModuleKey: number = Math.floor(Math.random() * 999);
  public searchPermissionInput: string = '';
  public isRootModuleExpanded: boolean = false;
  public rootModuleContainerStyleObject = { paddingBottom: `0.5rem` };

  get AssignExceptionsToModule() {
    return AssignExceptionsToModule;
  }

  get infoHeader(): string {
    return this.isInstances
      ? this.selectedInstance
        ? `Define exceptions to ${this.selectedInstance.name}`
        : `Define exceptions to selected instance(s)`
      : this.resourceType === ResourceTypes.GROUP
      ? `Define exceptions to Group`
      : `Define exceptions to User`;
  }

  public handleAssignedException(module: Role): void {
    this.moduleExceptionsTree = module;
    this.$emit('assignedExceptions', module);
  }

  @Watch('searchPermissionInput')
  @Debounce(500)
  public onSearchPermissionInput(): void {
    this.assignExceptionsToModuleKey += 1;
  }
}
