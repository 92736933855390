
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ModuleTree } from '@/store/modules/module-tree/types/module-tree.types';
import Treeselect, { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { Action, State } from 'vuex-class';
import {
  FilteredGroupPayload,
  GetGroupsResponsePayload,
  Group,
  Pagination
} from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import { TreeSelectElement } from '@/views/AdminGroupManagement/components/LicenseFormModal.vue';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
// tslint:disable-next-line:max-line-length
import { SelectedGroupsForVueTreeselect } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

export enum GrantAccessTo {
  ALL = 'all',
  SUBDIVISIONS = 'subdivisions',
  INSTANCES = 'instances'
}

@Component({
  components: { Treeselect },
  name: 'ModuleBranch'
})
export default class ModuleBranch extends Vue {
  $refs!: {
    treeselect: TreeSelectElement;
  };

  @Prop() public moduleTree!: ModuleTree;

  @Action('admin/getGroups')
  getGroups!: (
    options: FilteredGroupPayload
  ) => Promise<GetGroupsResponsePayload>;

  @Action('admin/getSpecificGroups')
  getSpecificGroups!: (groupIds: number[]) => Promise<GetGroupsResponsePayload>;

  @Action('admin/getSimplifiedGroups')
  getSimplifiedGroups!: (query: string) => Promise<Group[]>;

  @Action('rolesAndPermissions/setSelectedGroupsForVueTreeselect')
  setSelectedGroupsForVueTreeselect!: (
    groups: SelectedGroupsForVueTreeselect[]
  ) => void;

  @Action('rolesAndPermissions/unsetGroupsForVueTreeselect')
  unsetSelectedGroupsForVueTreeselect!: () => void;

  @State(({ admin }: RootState) => admin.simplifiedGroups)
  private groups: Group[] | undefined;

  @State(({ admin }: RootState) => admin.specificGroups)
  private specificGroups: Group[] | undefined;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.selectedGroupsForVueTreeselect
  )
  private instanceOptions: SelectedGroupsForVueTreeselect[] | undefined;

  private grantAccessTo: GrantAccessTo = GrantAccessTo.ALL;
  // branchKey is used to trigger component re-render when a module is toggled
  private branchKey = '';
  private isExpanded = false;
  private subdivisionIds: string[] = [];
  private instanceIds: number[] = [];

  public selectBranchPermissions(value: boolean) {
    this.toggleModule(value);
  }

  private created() {
    switch (this.moduleTree.entityType) {
      case EntityTypes.GROUP:
        if (
          this.moduleTree.instanceIds &&
          this.moduleTree.instanceIds.length > 0
        ) {
          this.getSpecificGroups(this.moduleTree.instanceIds);
        }
        break;
      default:
        break;
    }
  }

  private mounted(): void {
    this.isExpanded = this.moduleTree.expanded ?? false;
    /**
     * This function runs when the component is used to load an
     * existing module tree.
     */
    this.prepModuleTree();
  }

  private get subdivisionsLength(): number {
    return this.moduleTree.subdivisions?.length || 0;
  }

  private get moduleDescription(): string {
    return this.moduleTree.description
      ? `- ${this.moduleTree.description}`
      : '';
  }

  private get subdivisionOptions(): Array<{
    id: string;
    label: string | undefined;
  }> {
    return this.moduleTree?.subdivisions.map((subdivision) => {
      return { id: subdivision.subdivision, label: subdivision.label };
    });
  }

  // Functions that return CSS classes or values
  private getModuleLeftPadding(level: number): string {
    return `${16 * level}px`;
  }

  private getBottomPaddingForModulesWithSubModules(module: ModuleTree): string {
    if (module && module.submodules.length > 0) {
      return 'pb-1';
    }

    return 'pb-0';
  }

  private getChevronDirection(): string {
    if (this.isExpanded) {
      return 'mdi mdi-chevron-up pointer';
    }

    return 'mdi mdi-chevron-down pointer';
  }

  private getLevelStyle(moduleTree: ModuleTree): string {
    if (moduleTree.level < 2) {
      if (moduleTree.level === 1 && moduleTree.submodules.length === 0) {
        return `level-general`;
      } else {
        return `level-${moduleTree.level}-style`;
      }
    } else {
      return `level-general`;
    }
  }

  // For vue-treeselect
  private async loadOptions({
    action,
    searchQuery,
    callback
  }: {
    action: string;
    searchQuery: string;
    callback: (
      error: Error | null,
      parentNode: Array<{ id: number; label: string }> | undefined
    ) => void;
  }): Promise<void> {
    if (action === ASYNC_SEARCH) {
      /**
       * This Vuex action is invoked every time the user searches for a group.
       * This is a break from the regular SOP. Normally, actions are invoked
       * in `mounted()` or `created()` and is non-blocking.
       */
      await this.getSimplifiedGroups(searchQuery);

      if (this.groups) {
        const loadOptions = this.groups.map((group) => {
          return {
            id: group.id,
            label: group.name
          };
        });
        this.setSelectedGroupsForVueTreeselect(loadOptions);
      }

      callback(null, this.instanceOptions);
    }
  }

  /**
   * The vue-treeselect component is passed an array of instance IDs. However,
   * the labels of these instances are not present. As such, based on the
   * entity type, the vue-treeselect component data is traversed and its
   * labels are updated.
   *
   * As of the writing of this component, the only entity type that permissions
   * matrix is dealing with is GROUP.
   * @private
   */
  private initInstanceDropdown() {
    if (this.instanceIds.length > 0) {
      switch (this.moduleTree.entityType) {
        case EntityTypes.GROUP:
          this.loadGroupOptions();
          break;
        default:
          break;
      }
    }
  }

  private loadGroupOptions() {
    if (this.specificGroups) {
      const temp = this.specificGroups
        ? this.specificGroups
            .filter((group) => this.instanceIds.includes(group.id))
            .map((group) => ({ id: group.id, label: group.name }))
        : [];
      this.setSelectedGroupsForVueTreeselect(temp);

      if (this.instanceOptions) {
        for (const instanceId of this.instanceIds) {
          const group = this.instanceOptions.find(
            (item) => item.id === instanceId
          );
          if (this.$refs.treeselect) {
            (this.$refs.treeselect as TreeSelectElement).forest.nodeMap[
              instanceId
            ].label = group ? group.label : `Group ${instanceId}`;
          }
        }
      }
    }
  }

  private toggleSubdivision(data: { id: string; label: string }): void {
    const subdivisions = this.moduleTree.subdivisions;
    const subdivisionIndex = subdivisions.findIndex(
      (item) => item.subdivision === data.id
    );
    subdivisions[subdivisionIndex].checked = !subdivisions[subdivisionIndex]
      .checked;
    /**
     * The entirety of the `subdivisions` object is replaced to trigger change
     * detection.
     */
    this.moduleTree.subdivisions = subdivisions;
  }

  private toggleInstance(): void {
    /**
     * A microtask is used to assign new value to `instanceIds` as
     * `toggleInstance` is fired before the instanceIds property is updated by
     * Vue. Delegating the task to a microtask queue ensures the assignment
     * happens **after** Vue is done updating `instanceIds`.
     */
    queueMicrotask(() => {
      this.moduleTree.instanceIds = this.instanceIds;
    });
  }

  // Functions that change state of the moduleTree object
  private toggleModule(value: boolean): void {
    this.toggleSubmodules(value, this.moduleTree);
    this.emitSelectionToParent();
  }

  private toggleSubmodules(value: boolean, moduleTree: ModuleTree): void {
    moduleTree.checked = value;
    moduleTree.partiallyChecked = false;
    if (moduleTree.submodules.length > 0) {
      for (const branch of moduleTree.submodules) {
        this.toggleSubmodules(value, branch);
      }
    }
  }

  private emitSelectionToParent(): void {
    this.$emit('module-selected');
  }

  private handleModuleSelected(): void {
    /**
     * This ensures that the component stays expanded when it re-renders due to
     * a branch being toggled
     */
    this.isExpanded = true;
    this.moduleTree.partiallyChecked =
      this.checkIfModulesHavePartialSelection(this.moduleTree.submodules) ||
      this.countPartial(this.moduleTree.submodules) > 0;
    this.moduleTree.checked = this.checkIfModuleFullySelected(
      this.moduleTree.submodules
    );
    this.emitSelectionToParent();
  }

  private countPartial(modules: ModuleTree[]): number {
    return modules.reduce(
      (count, obj) => count + (obj.partiallyChecked ? 1 : 0),
      0
    );
  }

  private countCheckedTrue(modules: ModuleTree[]): number {
    return modules.reduce((count, obj) => count + (obj.checked ? 1 : 0), 0);
  }

  private checkIfModulesHavePartialSelection(
    moduleTree: ModuleTree[]
  ): boolean {
    const firstRun = this.checkIfModulePartiallySelected(moduleTree);
    if (firstRun) {
      return true;
    }

    // Check nested objects
    for (const module of moduleTree) {
      this.checkIfModulesHavePartialSelection(module.submodules);
    }

    return false;
  }

  private checkIfModulePartiallySelected(moduleTree: ModuleTree[]): boolean {
    const selectedCount = this.countCheckedTrue(moduleTree);
    const maxCount = moduleTree.length;
    return selectedCount >= 1 && selectedCount < maxCount;
  }

  private checkIfModuleFullySelected(moduleTree: ModuleTree[]): boolean {
    const selectedCount = this.countCheckedTrue(moduleTree);
    const maxCount = moduleTree.length;
    return selectedCount === maxCount;
  }

  private getKey(module: ModuleTree): string {
    return `${module.id + 1}-${module.checked}`;
  }

  private toggleDrawer(): void {
    this.isExpanded = !this.isExpanded;
    /**
     * Storing the state of the isExpanded variable inside the moduleTree
     * object lets us retain the original state of the component when it is
     * re-rendered.
     */
    this.moduleTree.expanded = this.isExpanded;
  }

  // Functions that prep module tree when a role is being viewed
  private prepModuleTree(): void {
    // Check if subdivisions selected
    this.setSubdivisions();
    // Check if instances selected
    this.setInstances();

    /**
     * Retain user selection regardless of the presence of subdivision or
     * instance IDs. If user selection is not there (for example, when an
     * existing role has been opened for editing), look for the presence of
     * subdivisions or instance IDs to set the value of grantAccessTo.
     */
    if (this.moduleTree.grantAccessTo) {
      this.grantAccessTo = this.moduleTree.grantAccessTo;
    } else if (this.subdivisionIds.length > 0) {
      this.moduleTree.grantAccessTo = GrantAccessTo.SUBDIVISIONS;
      this.grantAccessTo = GrantAccessTo.SUBDIVISIONS;
    } else if (this.instanceIds.length > 0) {
      this.moduleTree.grantAccessTo = GrantAccessTo.INSTANCES;
      this.grantAccessTo = GrantAccessTo.INSTANCES;
    } else {
      this.moduleTree.grantAccessTo = GrantAccessTo.ALL;
      this.grantAccessTo = GrantAccessTo.ALL;
    }
  }

  private setSubdivisions(): void {
    const subdivisionsSelected = this.moduleTree.subdivisions.some(
      (subdivision) => subdivision.checked
    );
    if (subdivisionsSelected) {
      this.subdivisionIds = this.moduleTree.subdivisions
        .filter((item) => item.checked)
        .map((item) => item.subdivision);
    }
  }

  private setInstances(): void {
    if (this.moduleTree.instanceIds && this.moduleTree.instanceIds.length > 0) {
      this.instanceIds = this.moduleTree.instanceIds;
    }
  }

  private onChangeGrantAccessRadioButton(grantAccessTo: string) {
    switch (grantAccessTo) {
      case GrantAccessTo.ALL:
        this.moduleTree.grantAccessTo = GrantAccessTo.ALL;
        break;
      case GrantAccessTo.SUBDIVISIONS:
        this.moduleTree.grantAccessTo = GrantAccessTo.SUBDIVISIONS;
        break;
      case GrantAccessTo.INSTANCES:
        this.moduleTree.grantAccessTo = GrantAccessTo.INSTANCES;
        break;
      default:
        break;
    }
  }

  @Watch('specificGroups')
  private watchGroups() {
    if (this.moduleTree.entityType === EntityTypes.GROUP) {
      if (
        (this.$store.state as RootState).admin.apiState.getSpecificGroups
          .success
      ) {
        /**
         * State of vue-treeselect component is updated once it is ready.
         */
        this.$nextTick(() => {
          if (this.$refs.treeselect) {
            this.initInstanceDropdown();
          }
        });
      }
    }
  }

  @Watch('grantAccessTo')
  private watchGrantAccessTo() {
    if (this.grantAccessTo === 'instances') {
      if (this.moduleTree.instanceIds) {
        this.getSpecificGroups(this.moduleTree.instanceIds);
      }
    }
  }
}
