













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Group, User } from '../../../store/modules/admin/types/admin.types';
import {
  ModuleExceptionTypes,
  ModuleTreeRecord,
  PermissionHelperPayload,
  Role,
  RoleEntity,
  RolesAndExceptionsResponse,
  RoleScopeDto
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import ResourceDetailsComponent from './ResourceDetailsComponent.vue';
import ResourceRolesAndExceptionsInfoTemplate from './ResourceRolesAndExceptionsInfoTemplate.vue';
import ViewDetailedAccessConditions from './ViewDetailedAccessConditions.vue';
@Component({
  components: {
    ResourceRolesAndExceptionsInfoTemplate,
    ResourceDetailsComponent,
    ViewDetailedAccessConditions
  }
})
export default class ResourceRolesAndExceptions extends Vue {
  @Prop() public rolesAndExceptionsForResource!: RolesAndExceptionsResponse;
  @Prop() public permissionHelperPayload!: PermissionHelperPayload;
  @Prop() public selectedResource!: User | Group;
  @Prop() public selectedModule!: ModuleTreeRecord;
  @Prop() public selectedModuleAction!: Role;
  @Prop() public selectedInstance!: Group;
  public isInstances: boolean = this.selectedInstance ? true : false;

  get ResourceRolesAndExceptionsInfoTemplate() {
    return ResourceRolesAndExceptionsInfoTemplate;
  }

  get roles(): Array<{
    role: RoleEntity;
    roleScopes: RoleScopeDto[] | null;
    roleTree: Role[];
  }> {
    return (
      this.rolesAndExceptionsForResource.roles.filter((role) => {
        const roleTree = role.roleTree.filter(
          (rt) => rt.checked || rt.partiallyChecked
        );
        return roleTree.length > 0;
      }) || []
    );
  }

  get exceptions(): Array<{ roleTree: Role; instance?: Group }> {
    return (
      this.rolesAndExceptionsForResource.exceptions.filter(
        (exception) =>
          exception.roleTree.exception !== ModuleExceptionTypes.NONE
      ) || []
    );
  }

  public openLink() {
    this.$router.push({
      name: 'roles-and-permissions',
      query: {
        tab: 'Roles'
      }
    });
    this.$emit('close');
  }
}
