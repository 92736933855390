



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Group, User } from '../../../store/modules/admin/types/admin.types';
import {
  ModuleTreeRecord,
  ResourceTypes
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
@Component({
  components: {}
})
export default class ResourceDetailsComponent extends Vue {
  @Prop({ default: ResourceTypes.USER }) public resourceType!: ResourceTypes;
  @Prop() public selectedResource!: User | Group;
  @Prop() public selectedModule!: ModuleTreeRecord;
  @Prop() public selectedInstances!: Group[];
  @Prop({ default: false }) public hasExistingExceptions!: boolean;
  @Prop({ default: false, type: Boolean }) public isInstanceTag!: boolean;

  get instances(): Group[] {
    return this.selectedInstances?.filter((instance) => instance !== null);
  }

  get resource():
    | {
        resourceType: string;
        resourceId: string;
      }
    | undefined {
    switch (this.resourceType) {
      case ResourceTypes.USER:
        this.selectedResource = this.selectedResource as User;
        return {
          resourceType: 'User',
          resourceId: this.selectedResource.email
        };
      case ResourceTypes.GROUP:
        this.selectedResource = this.selectedResource as Group;
        return {
          resourceType: 'Group',
          resourceId: this.selectedResource.name
        };
      default:
        return;
    }
  }

  get moduleName(): string {
    return this.selectedModule.label;
  }
}
