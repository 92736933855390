













import { Component, Vue } from 'vue-property-decorator';
import {
  PaginatorSpecs,
  SortOrder,
  SortSpecs
} from '../../store/types/general.types';
import BasicSearchAndCreateNew from './components/BasicSearchAndCreateNewComponent.vue';

@Component({
  components: { BasicSearchAndCreateNew }
})
export default class RoleTemplateTab extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'roleTemplate';
  public sortOrder: SortOrder = SortOrder.ASC;
  public searchRoleTemplateParam: string = '';

  get searchRouteQueryParamRoleTemplate(): string {
    return (this.$route.query.roleTemplate as string) || '';
  }

  public mounted(): void {
    this.perPage = +this.$route.query.limit || this.perPage;
    this.page = +this.$route.query.page || this.page;
    this.sortColumn =
      (this.$route.query.sortColumn as string) || this.sortColumn;
    this.sortOrder =
      (this.$route.query.sortOrder as SortOrder) || this.sortOrder;
    this.searchRoleTemplateParam = this.searchRouteQueryParamRoleTemplate;

    this.getRoleTemplates();
  }

  public openCreateNewRoleTemplateModal(): void {
    // TODO: create modal to add new exception
  }

  public handleSearch(searchInput: string): void {
    if (this.searchRouteQueryParamRoleTemplate !== searchInput) {
      this.searchRoleTemplateParam = searchInput;
      this.getRoleTemplates();
    }
  }

  public handlePaginator(paginator: PaginatorSpecs): void {
    this.perPage = paginator.perPage || +this.$route.query.limit;
    this.page = paginator.page || +this.$route.query.page;
    this.getRoleTemplates();
  }

  public handleSort(sort: SortSpecs): void {
    this.sortColumn = sort.sortColumn;
    this.sortOrder = sort.sortOrder;
    this.getRoleTemplates();
  }

  public getRoleTemplates(): void {
    /** set router query params */
    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'RoleTemplates',
        roleTemplate: this.searchRoleTemplateParam,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder: (this.$route.query.sortOrder as SortOrder) || this.sortOrder
      }
    });

    /** TODO: call api to fetch role templates */
  }
}
