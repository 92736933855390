










































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseTable from '../../../components/BaseTable.vue';
import { Group, User } from '../../../store/modules/admin/types/admin.types';
import {
  GetPermissionsResponse,
  ModuleTreeRecord,
  PermissionHelperPayload,
  Role
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import ResourceDetailsComponent from './ResourceDetailsComponent.vue';
import ResourcePermissionRowComponent from './ResourcePermissionRowComponent.vue';
import ResourceRolesAndExceptionsInfoTemplate from './ResourceRolesAndExceptionsInfoTemplate.vue';
@Component({
  components: {
    ResourceDetailsComponent,
    ResourcePermissionRowComponent,
    ResourceRolesAndExceptionsInfoTemplate,
    BaseTable
  }
})
export default class ResourcePermissionsComponent extends Vue {
  @Prop() public permissions!: GetPermissionsResponse;
  @Prop() public permissionHelperPayload!: PermissionHelperPayload;
  @Prop() public selectedResource!: User | Group;
  @Prop() public selectedModule!: ModuleTreeRecord;
  @Prop() public selectedInstance!: Group;

  get ResourcePermissionRowComponent() {
    return ResourcePermissionRowComponent;
  }

  get resourceType() {
    return this.permissionHelperPayload.resourceType;
  }

  get getRole() {
    return this.permissions.role && this.permissions.role.length
      ? this.permissions.role[0]
      : null;
  }

  get submodulesWithPermissions() {
    return this.permissions.role && this.permissions.role.length
      ? this.permissions.role[0].submodules
      : [];
  }

  public openLink() {
    this.$router.push({
      name: 'roles-and-permissions',
      query: {
        tab: 'Roles'
      }
    });
    this.$emit('close');
  }

  public selectedModuleAction(selectedModuleAction: Role) {
    this.$emit('selectedModuleAction', selectedModuleAction);
  }
}
