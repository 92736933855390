
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ModuleTree } from '@/store/modules/module-tree/types/module-tree.types';
import ModuleBranch from '@/components/ModuleBranch.vue';
import { ModuleActionTypes } from '@/store/modules/module-tree/enums/module-tree.enums';

/**
 * This component forms the base of the Role component. ModuleTree can be fed
 * a module tree object. It can also output a module tree object (used during
 * role creation and update).
 *
 * As you can see from the template below, each 'tree' can container multiple
 * branches. Each 'branch' can have its own set of branches/submodules.
 * The components are rendered recursively.
 *
 * Terminology:
 * - branch = module = child
 * - branches = submodules = children
 *
 * Changes done in one branch are propagated both ways:
 * - When a child component is toggled/checked, an event emits which is
 *   then handled by its parent. If a nested module is selected and its parent
 *   is 2 levels up, the parent has to be marked as 'partially checked'. As
 *   such, an event emitted by a child propagates upwards until it reaches the
 *   base/root module.
 * - If the above component also has its own branches/submodules, the module's
 *   submodules array is updated recursively. Since JS passes objects by
 *   reference, these changes are reflected in the child components. This update
 *   is not done through props but by updating the components' 'key' values
 *   as they are being rendered in v-for.
 */
@Component({
  components: { ModuleBranch }
})
export default class ModuleTreeComponent extends Vue {
  $refs!: {
    moduleBranches: ModuleBranch[];
  };

  @Prop() public moduleTree!: ModuleTree[];

  private branchKey: string = '';
  private currentBranch: ModuleTree = {
    id: 0,
    parentId: 0,
    moduleName: '',
    hasInstances: false,
    action: ModuleActionTypes.ALL,
    partiallyChecked: false,
    checked: false,
    submodules: [],
    subdivisions: [],
    level: 0
  };
  private isReady = false;

  public selectAllPermissions(value: boolean) {
    for (const branch of this.$refs.moduleBranches) {
      branch.selectBranchPermissions(value);
    }
  }

  private mounted() {
    this.isReady = true;
  }

  private getKey(module: ModuleTree): string {
    this.currentBranch = module;
    return `${module.id + 1}-${module.checked}`;
  }

  private handleModuleSelected() {
    this.$emit('module-toggled');
  }

  @Watch('moduleTree.checked', { deep: true })
  private watchModuleTreeChecked(): void {
    if (this.currentBranch) {
      this.branchKey = `${this.currentBranch.id + 2}-${
        this.currentBranch.checked
      }`;
    }
  }
}
