


























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { Group, User } from '../../../store/modules/admin/types/admin.types';
import {
  GetPermissionsResponse,
  ModuleTreeRecord,
  PermissionHelperPayload,
  PermissionHelperRolesAndExceptionPayload,
  ResourceTypes,
  Role,
  RolesAndExceptionsResponse
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import SelectGroupListComponent from './SelectGroupListComponent.vue';
import SelectUserListComponent from './SelectUserListComponent.vue';
import ModuleSearchComponent from './ModuleSearchComponent.vue';
import ResourcePermissionsComponent from './ResourcePermissionsComponent.vue';
import ResourceRolesAndExceptions from './ResourceRolesAndExceptions.vue';
import { EntityTypes } from '../../../store/modules/module-tree/enums/module-tree.enums';

const initialPermissionHelperPayload: PermissionHelperPayload = {
  resourceType: ResourceTypes.USER,
  resourceId: 0,
  moduleName: '',
  instanceId: 0,
  instanceType: EntityTypes.GROUP
};
@Component({
  components: {
    SelectUserListComponent,
    SelectGroupListComponent,
    ModuleSearchComponent,
    ResourcePermissionsComponent,
    ResourceRolesAndExceptions
  }
})
export default class PermissionHelperModal extends Vue {
  public stepsIndex: number = 0;
  public permissionHelperPayload: PermissionHelperPayload = JSON.parse(
    JSON.stringify(initialPermissionHelperPayload)
  );
  public selectedResource: User | Group | null = null;
  public selectedModule: ModuleTreeRecord | null = null;
  public selectedInstance: Group | null = null;
  public selectedModuleAction: Role | null = null;
  public userListComponentKey: number = Math.floor(Math.random() * 999);
  public groupListComponentKey: number = Math.floor(Math.random() * 999);
  public moduleSearchComponentKey: number = Math.floor(Math.random() * 999);
  public instanceListComponentKey: number = Math.floor(Math.random() * 999);
  public isButtonDisable: boolean = true;
  @Action('rolesAndPermissions/getPermissionsForResource')
  public getPermissionsForResource!: (
    payload: PermissionHelperPayload
  ) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.getPermissionsForResource
  )
  public getPermissionsForResourceState!: ApiState;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.permissionsForResource
  )
  public permissionsForResource!: GetPermissionsResponse;

  @Action('rolesAndPermissions/getRolesAndExceptionsForResource')
  public getRolesAndExceptionsForResource!: (
    payload: PermissionHelperRolesAndExceptionPayload
  ) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.getRolesAndExceptionsForResource
  )
  public getRolesAndExceptionsForResourceState!: ApiState;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.rolesAndExceptionsForResource
  )
  public rolesAndExceptionsForResource!: RolesAndExceptionsResponse;

  get SelectUserListComponent() {
    return SelectUserListComponent;
  }

  get SelectGroupListComponent() {
    return SelectGroupListComponent;
  }

  get ModuleSearchComponent() {
    return ModuleSearchComponent;
  }

  get ResourceRolesAndExceptions() {
    return ResourceRolesAndExceptions;
  }

  get ResourceTypes() {
    return ResourceTypes;
  }

  get isInstanceSelectionAllowed(): boolean {
    return this.selectedModule ? this.selectedModule.hasInstances : false;
  }

  public isFormValid() {
    if (
      this.permissionHelperPayload.resourceId !== 0 &&
      this.permissionHelperPayload.moduleName !== ''
    ) {
      this.isButtonDisable = false;
    } else {
      this.isButtonDisable = true;
    }
  }

  public handleResetHelper() {
    this.permissionHelperPayload = JSON.parse(
      JSON.stringify(initialPermissionHelperPayload)
    );
    this.selectedResource = null;
    this.selectedModule = null;
    this.selectedInstance = null;
    this.isButtonDisable = true;
    this.stepsIndex = 0;
    this.userListComponentKey += 1;
    this.groupListComponentKey += 1;
    this.moduleSearchComponentKey += 1;
    this.instanceListComponentKey += 1;
  }

  public handleSelectedResource(resource: User | Group | null) {
    if (resource === null) {
      this.selectedResource = null;
      this.permissionHelperPayload.resourceId = 0;
    } else {
      if (this.permissionHelperPayload.resourceType === ResourceTypes.USER) {
        this.selectedResource = resource as User;
        this.permissionHelperPayload.resourceId = this.selectedResource.userId;
      }
      if (this.permissionHelperPayload.resourceType === ResourceTypes.GROUP) {
        this.selectedResource = resource as Group;
        this.permissionHelperPayload.resourceId = this.selectedResource.id;
      }
    }
    this.isFormValid();
  }

  public handleSelectedInstance(group: Group | null) {
    if (group === null) {
      this.selectedInstance = null;
      this.permissionHelperPayload.instanceId = 0;
    } else {
      this.selectedInstance = group;
      this.permissionHelperPayload.instanceId = this.selectedInstance.id;
    }
  }

  public handleSelectedModule(module: ModuleTreeRecord | null) {
    if (module === null) {
      this.permissionHelperPayload.moduleName = '';
      this.selectedModule = null;
    } else {
      this.permissionHelperPayload.moduleName = module.name;
      this.selectedModule = module;
    }

    if (!this.isInstanceSelectionAllowed) {
      this.selectedInstance = null;
      this.permissionHelperPayload.instanceId = 0;
    }
    this.isFormValid();
  }

  public getPermissionsForResourceByModule() {
    const payload: PermissionHelperPayload = {
      resourceType: this.permissionHelperPayload.resourceType,
      resourceId: this.permissionHelperPayload.resourceId,
      moduleName: this.permissionHelperPayload.moduleName
    };
    if (this.permissionHelperPayload.instanceId) {
      payload.instanceId = this.permissionHelperPayload.instanceId;
      payload.instanceType = this.permissionHelperPayload.instanceType;
    }
    this.getPermissionsForResource(payload);
  }

  public closeModal(): void {
    this.$emit('close');
  }

  public prevStep(): void {
    if (this.selectedModule && this.selectedModule.hasSubmodules) {
      this.stepsIndex -= 1;
    } else {
      this.stepsIndex -= 2;
    }
  }

  public handleSelectedModuleAction(selectedModuleAction: Role) {
    const payload: PermissionHelperRolesAndExceptionPayload = {
      resourceType: this.permissionHelperPayload.resourceType,
      resourceId: this.permissionHelperPayload.resourceId,
      moduleName: selectedModuleAction.moduleName
    };
    if (this.permissionHelperPayload.instanceId) {
      payload.instanceIds = [this.permissionHelperPayload.instanceId];
      payload.instanceType = this.permissionHelperPayload.instanceType;
    }
    this.selectedModuleAction = selectedModuleAction;
    this.getRolesAndExceptionsForResource(payload);
  }

  @Watch('permissionHelperPayload.resourceType')
  onResourceTypeChange(): void {
    this.permissionHelperPayload.resourceId = 0;
    this.isFormValid();
  }

  @Watch('getPermissionsForResourceState')
  onGetPermissionsForResourceState(state: ApiState) {
    if (state.success) {
      if (this.selectedModule && this.selectedModule.hasSubmodules) {
        this.stepsIndex = 1;
      } else {
        const payload: PermissionHelperRolesAndExceptionPayload = {
          resourceType: this.permissionHelperPayload.resourceType,
          resourceId: this.permissionHelperPayload.resourceId,
          moduleName: this.permissionHelperPayload.moduleName
        };
        if (this.permissionHelperPayload.instanceId) {
          payload.instanceIds = [this.permissionHelperPayload.instanceId];
          payload.instanceType = this.permissionHelperPayload.instanceType;
        }
        this.selectedModuleAction = this.permissionsForResource.role[0];
        this.getRolesAndExceptionsForResource(payload);
      }
    }
  }

  @Watch('getRolesAndExceptionsForResourceState')
  onGetRolesAndExceptionsForResourceState(state: ApiState) {
    if (state.success) {
      this.stepsIndex = 2;
    }
  }
}
