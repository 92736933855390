

















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../components/base/BaseLoading.vue';
import BaseTable from '../../components/BaseTable.vue';
import { ModuleTree } from '../../store/modules/module-tree/types/module-tree.types';
import { RootState } from '../../store/store';
import { ApiState } from '../../store/types/general.types';
import BasicSearchAndCreateNew from './components/BasicSearchAndCreateNewComponent.vue';
import CreateExceptionsModal from './components/CreateExceptionsModal.vue';

@Component({
  components: { BasicSearchAndCreateNew, BaseLoading, BaseTable }
})
export default class ExceptionTab extends Vue {
  public searchExceptionParam: string = '';
  public isLoading: boolean = false;
  public filteredModuleTree: ModuleTree[] = [];

  @Action('moduleTree/getModuleTree')
  public getModuleTree!: () => Promise<ModuleTree[]>;

  @State(({ moduleTree }: RootState) => moduleTree.apiState.getModuleTree)
  public getModuleTreeState!: ApiState;

  @State(({ moduleTree }: RootState) => moduleTree.moduleTree)
  public moduleTree!: ModuleTree[];

  public getFilteredModuleTree(moduleTree: ModuleTree[]): ModuleTree[] {
    const module: ModuleTree[] = JSON.parse(JSON.stringify(moduleTree));
    if (this.searchExceptionParam) {
      return module.filter((branch) => {
        if (
          branch.label
            ?.toLowerCase()
            .includes(this.searchExceptionParam.toLowerCase())
        ) {
          return branch;
        } else {
          branch.submodules = branch.submodules.filter((subModule) => {
            if (
              subModule.label
                ?.toLowerCase()
                .includes(this.searchExceptionParam.toLowerCase())
            ) {
              return subModule;
            }
          });
          if (branch.submodules.length > 0) {
            return branch;
          }
        }
      });
    }

    return module;
  }

  public mounted(): void {
    this.getModuleTree();
    this.isLoading = true;
  }

  public openViewModuleExceptions(subModule: ModuleTree) {
    this.$router.push(`exceptions/${subModule.moduleName}`);
  }

  public openCreateNewExceptionModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: CreateExceptionsModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {},
      events: {}
    });
  }

  public handleSearch(searchInput: string): void {
    this.searchExceptionParam = searchInput;
    this.filteredModuleTree = this.getFilteredModuleTree(this.moduleTree);
  }

  @Watch('getModuleTreeState')
  onGetModuleTreeState(state: ApiState): void {
    if (state.loading) {
      this.isLoading = true;
    } else {
      this.isLoading = false;
      this.filteredModuleTree = this.getFilteredModuleTree(this.moduleTree);
    }
  }
}
