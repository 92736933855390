




































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseTable from '../../../components/BaseTable.vue';
import { Group } from '../../../store/modules/admin/types/admin.types';
import {
  ModuleExceptionTypes,
  ModulePermissionTypes,
  Role,
  RoleEntity
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
@Component({
  components: { BaseTable }
})
export default class ResourceRolesAndExceptionsInfoTemplate extends Vue {
  @Prop() public selectedModuleAction!: Role;
  @Prop({ default: false }) public isSuperAdmin!: boolean;
  @Prop({ default: false }) public isInstances!: boolean;
  @Prop() public roles!: Array<{
    role: RoleEntity;
    roleTree: Role[];
  }>;
  @Prop() public exceptions!: Array<{ roleTree: Role; instance?: Group }>;

  get ModulePermissionTypes() {
    return ModulePermissionTypes;
  }
  get moduleActionLabel(): string {
    let html = `<b>${this.selectedModuleAction.label}</b>`;
    if (this.selectedModuleAction.description) {
      html += `<small> - ${this.selectedModuleAction.description}</small>`;
    }
    return html;
  }

  get isRoleHavePartialPermissions(): boolean {
    return this.roles.some((role) => role.roleTree[0].checked !== true);
  }

  get isExceptionsHavePartialPermissions(): boolean {
    return this.exceptions.some(
      (exception) => exception.roleTree.exception === ModuleExceptionTypes.MIXED
    );
  }

  get isExceptionsHaveForModule(): boolean {
    return this.exceptions.some((exception) => !exception.instance);
  }

  get isExceptionsHaveInstances(): boolean {
    return this.exceptions.some((exception) => exception.instance);
  }

  get isExceptionsHaveGrantedPermissions(): boolean {
    return this.exceptions.some(
      (exception) => exception.roleTree.exception === ModuleExceptionTypes.ALLOW
    );
  }
}
