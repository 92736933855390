































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TreeTableRowComponent from '../../../components/TreeTableRowComponent.vue';
import { Role } from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
@Component({
  components: { TreeTableRowComponent },
  name: 'ResourcePermissionRowComponent'
})
export default class ResourcePermissionRowComponent extends Vue {
  @Prop() public modulePermissions!: Role;
  @Prop({ type: Number, default: 1 }) public level!: number;

  public expanded: boolean = false;

  get TreeTableRowComponent() {
    return TreeTableRowComponent;
  }

  get submodules(): Role[] {
    return this.modulePermissions.submodules;
  }

  get noSubmodules(): boolean {
    return !this.modulePermissions.submodules.length;
  }

  get getKey(): number {
    return Math.floor(Math.random() * 999);
  }

  public getPermission(module: Role) {
    return module.permission;
  }

  public selectedModuleAction(selectedModuleAction: Role): void {
    this.$emit('selectedModuleAction', selectedModuleAction);
  }

  public getDetailedPermissions(selectedModuleAction: Role): void {
    this.$emit('selectedModuleAction', selectedModuleAction);
  }
}
