

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TreeTableRowComponent from '../../../components/TreeTableRowComponent.vue';
import { Role } from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
@Component({
  components: { TreeTableRowComponent },
  name: 'ResourceRoleTreeComponent'
})
export default class ResourceRoleTreeComponent extends Vue {
  @Prop({ type: Number, default: 0 }) public level!: number;
  @Prop({ type: Boolean, default: false }) public shouldBeExpanded!: boolean;
  @Prop() public role!: Role;

  public expanded: boolean = this.shouldBeExpanded;

  get TreeTableRowComponent() {
    return TreeTableRowComponent;
  }

  get submodules() {
    return this.role.submodules;
  }

  get noSubmodules() {
    return !this.role.submodules.length;
  }

  get moduleTree() {
    return this.role;
  }
}
