

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
@Component({
  components: {}
})
/**
 * This component includes
 * 1. single search input
 * 2. create new button
 */
export default class BasicSearchAndCreateNew extends Vue {
  @Prop(String) public searchPlaceholder!: string;
  @Prop(String) public createNewButtonText!: string;
  @Prop(String) public searchQueryParam!: string;

  public searchQueryInput: string = this.searchQueryParam || '';

  @Watch('searchQueryInput')
  @Debounce(500)
  public onSearch(): void {
    this.$emit('searchQueryInput', this.searchQueryInput);
  }

  public createNew(): void {
    this.$emit('createNew');
  }
}
