





























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  ModuleExceptionTypes,
  RolesAndExceptionsResponse,
  Role,
  RoleEntity,
  RoleScopeDto
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import ModuleSearchComponent from './ModuleSearchComponent.vue';
import TreeTableRowComponent from '../../../components/TreeTableRowComponent.vue';
import ViewDetailedAccessConditions from './ViewDetailedAccessConditions.vue';
import { Group } from '../../../store/modules/admin/types/admin.types';
import { boldSearchKeywords } from '../../../utils/search.util';

@Component({
  components: {
    ModuleSearchComponent,
    TreeTableRowComponent,
    ViewDetailedAccessConditions
  },
  name: 'AssignExceptionsToModule'
})
export default class AssignExceptionsToModule extends Vue {
  @Prop() public module!: Role;
  @Prop() public searchPermissionInput!: string;
  @Prop() public existingPermissions!: RolesAndExceptionsResponse;
  @Prop({ type: Number, default: 1 }) public level!: number;
  @Prop({ type: Boolean, default: false }) public isRootModule!: boolean;
  @Prop({ default: false }) isManageExceptions!: boolean;
  @Prop() public selectedInstance!: Group;

  public expanded: boolean =
    this.level === 1 ||
    (this.searchPermissionInput && this.searchPermissionInput.length > 0)
      ? true
      : false;
  public accessConditionsComponentKey: number = Math.floor(Math.random() * 999);
  public assignExceptionsToSubModuleKey: number = Math.floor(
    Math.random() * 999
  );
  public modulePermissions = JSON.parse(JSON.stringify(this.module));
  public showRolesAndExceptionsModal: boolean = false;
  public selectedModulesExistingRolesAndExceptions: RolesAndExceptionsResponse = {
    roles: [],
    exceptions: []
  };

  public modalTitle: string = '';

  public styleTableObject = {
    margin: !this.isRootModule ? `0 0 0 1.5rem` : 'none',
    borderLeft: !this.isRootModule ? '1px solid #dfdfdf' : 'none',
    paddingBottom:
      this.isRootModule && this.showRow(this.modulePermissions) ? '0.5rem' : '0'
  };

  get TreeTableRowComponent() {
    return TreeTableRowComponent;
  }

  get submodules(): Role[] {
    return this.modulePermissions.submodules;
  }

  get noSubmodules(): boolean {
    return !this.modulePermissions || !this.modulePermissions.submodules.length;
  }

  get exceptionOptions(): Array<{
    label: string;
    value: ModuleExceptionTypes;
  }> {
    return [
      { label: 'No exception', value: ModuleExceptionTypes.NONE },
      { label: 'Granted', value: ModuleExceptionTypes.ALLOW },
      { label: 'Blocked', value: ModuleExceptionTypes.DENY },
      { label: 'Mixed', value: ModuleExceptionTypes.MIXED }
    ];
  }

  get roles(): Array<{
    role: RoleEntity;
    roleScopes: RoleScopeDto[] | null;
    roleTree: Role[];
  }> {
    return (
      this.selectedModulesExistingRolesAndExceptions.roles.filter((role) => {
        const roleTree = role.roleTree.filter(
          (rt) => rt.checked || rt.partiallyChecked
        );
        return roleTree.length > 0;
      }) || []
    );
  }

  get exceptions(): Array<{ roleTree: Role; instance?: Group }> {
    return (
      this.selectedModulesExistingRolesAndExceptions.exceptions.filter(
        (exception) =>
          exception.roleTree.exception !== ModuleExceptionTypes.NONE
      ) || []
    );
  }

  public updateExceptionInputBoxCSS(modulePermissions: Role): void {
    /** Change css based on exception type */
    const elems = document.getElementById(
      `${modulePermissions.moduleName}ModuleException`
    );
    if (elems) {
      switch (modulePermissions.exception) {
        case ModuleExceptionTypes.ALLOW:
          elems.style.borderColor = '#32d420';
          break;
        case ModuleExceptionTypes.DENY:
          elems.style.borderColor = '#ff7171';
          break;
        case ModuleExceptionTypes.MIXED:
          elems.style.borderColor = '#ffd12c';
          break;
        default:
          elems.style.borderColor = '#dfdfdf';
          break;
      }
    }
  }

  public mounted(): void {
    this.updateExceptionInputBoxCSS(this.modulePermissions);
  }

  public isOptionHidden(option: {
    label: string;
    value: ModuleExceptionTypes;
  }): boolean {
    return option.value === ModuleExceptionTypes.MIXED ? true : false;
  }

  /** If module matches with search input display row */
  public showRow(module: Role) {
    if (this.searchPermissionInput.length > 0) {
      let isValid = false;
      if (
        module.label
          ?.toLowerCase()
          .includes(this.searchPermissionInput.toLowerCase())
      ) {
        isValid = true;
      }
      if (module.submodules && module.submodules.length > 0) {
        for (const submodule of module.submodules) {
          const data = this.showRow(submodule);
          if (data) {
            isValid = true;
          }
        }
      }
      return isValid;
    }
    return true;
  }

  public getExistingPermissionsForSelectedModule(selctedModule: Role) {
    this.modalTitle = `${selctedModule.label} Access Conditions`;
    this.showRolesAndExceptionsModal = true;
    this.accessConditionsComponentKey += 1;
    const existingRolesAndPermissions = this.existingPermissions;

    if (
      existingRolesAndPermissions &&
      existingRolesAndPermissions.roles &&
      existingRolesAndPermissions.roles.length > 0
    ) {
      const roles = existingRolesAndPermissions.roles
        .map((role) => {
          let moduleTree: Role | null = null;
          moduleTree = this.getfilteredModuleTreeByModuleName(
            moduleTree,
            role.roleTree,
            selctedModule.moduleName
          );
          return {
            role: role.role,
            roleScopes: role.roleScopes,
            roleTree: [moduleTree]
          };
        })
        .filter((role) => {
          const roleTree = role.roleTree.filter(
            (rt) => rt.checked || rt.partiallyChecked
          );
          return roleTree.length > 0;
        });

      this.selectedModulesExistingRolesAndExceptions.roles.push(...roles);
    }

    /**
     * Only Group Administration-Groups and it's child nodes will have instance(s),
     * hence this module will have multiple exception tree(moduleTree) for resource based on instance
     *
     * as other module(s) doesn't have instance, it will only have single exception tree(moduleTree)
     */
    if (
      existingRolesAndPermissions.exceptions &&
      existingRolesAndPermissions.exceptions.length > 0
    ) {
      existingRolesAndPermissions.exceptions.forEach((exception) => {
        const module: Role | null = null;
        const exceptions: Role = this.getfilteredModuleTreeByModuleName(
          module,
          [exception.roleTree],
          selctedModule.moduleName
        );

        const exceptionPayload: {
          roleTree: Role;
          instance?: Group;
        } = {
          roleTree: exceptions
        };
        if (exception.instance) {
          exceptionPayload.instance = exception.instance;
        }
        this.selectedModulesExistingRolesAndExceptions.exceptions.push(
          exceptionPayload
        );
      });
    }
  }

  public closeModal(): void {
    this.showRolesAndExceptionsModal = false;
    this.accessConditionsComponentKey += 1;
    this.selectedModulesExistingRolesAndExceptions = {
      roles: [],
      exceptions: []
    };
  }

  public getfilteredModuleTreeByModuleName(
    module: Role | null,
    moduleTree: Role[],
    moduleName: string
  ): Role {
    if (!module) {
      for (const branch of moduleTree) {
        if (branch.moduleName === moduleName) {
          module = branch;
          break;
        } else if (branch.submodules.length > 0) {
          module = this.getfilteredModuleTreeByModuleName(
            module,
            branch.submodules,
            moduleName
          );
        }
      }
    }
    return module as Role;
  }

  private get moduleLabel(): string | undefined {
    return boldSearchKeywords(
      this.modulePermissions.label,
      this.searchPermissionInput
    );
  }

  public assignExceptionToChild(
    modules: Role[],
    exception: ModuleExceptionTypes
  ): void {
    for (const module of modules) {
      module.exception = exception;
      if (module.submodules.length > 0) {
        this.assignExceptionToChild(module.submodules, exception);
      }
    }
  }

  public onExceptionInput(modulePermissions: Role): void {
    if (
      (modulePermissions.exception === ModuleExceptionTypes.ALLOW ||
        modulePermissions.exception === ModuleExceptionTypes.DENY ||
        modulePermissions.exception === ModuleExceptionTypes.NONE) &&
      modulePermissions.submodules.length > 0
    ) {
      this.assignExceptionToChild(
        this.modulePermissions.submodules,
        modulePermissions.exception
      );
      this.assignExceptionsToSubModuleKey += 1;
    }
    this.updateExceptionInputBoxCSS(this.modulePermissions);
    this.$emit('moduleExceptionAssign', this.modulePermissions);
  }

  public handleAssignedException(module: Role, index: number): void {
    this.modulePermissions.submodules.splice(index, 1, module);
    if (
      this.modulePermissions &&
      this.modulePermissions.submodules.length > 0
    ) {
      const subModuleExceptionTypes: ModuleExceptionTypes[] = [];
      for (const submodule of this.modulePermissions.submodules) {
        if (
          !subModuleExceptionTypes.includes(
            submodule.exception as ModuleExceptionTypes
          )
        ) {
          subModuleExceptionTypes.push(
            submodule.exception as ModuleExceptionTypes
          );
        }
      }
      if (subModuleExceptionTypes.length > 1) {
        this.modulePermissions.exception = ModuleExceptionTypes.MIXED;
      } else if (subModuleExceptionTypes.length === 1) {
        this.modulePermissions.exception = subModuleExceptionTypes[0];
      }
    }
    this.updateExceptionInputBoxCSS(this.modulePermissions);
    this.$emit('moduleExceptionAssign', this.modulePermissions);
  }

  @Watch('expanded')
  onExpanded(): void {
    if (this.isRootModule) {
      this.$emit('onModuleExpanded', this.expanded);
    }
  }
}
