var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSuperAdmin)?_c('div',{class:[_vm.$style.infoContainer, _vm.$style.allowContainer]},[_c('div',{class:_vm.$style.moduleActionLabel},[_c('i',{class:['mdi mdi-check-circle', _vm.$style.allowPermissionIcon]}),_c('b',[_vm._v(" Full Access ")])]),_c('p',[_vm._v("This user is a super admin and has full access to the system")])]):(
    _vm.selectedModuleAction.permission === _vm.ModulePermissionTypes.ALLOW ||
    _vm.isExceptionsHaveGrantedPermissions
  )?_c('div',{class:[_vm.$style.infoContainer, _vm.$style.allowContainer]},[_c('div',{class:_vm.$style.moduleActionLabel},[_c('i',{class:['mdi mdi-check-circle', _vm.$style.allowPermissionIcon]}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.moduleActionLabel)}})]),_c('p',[_vm._v("User has this permission (in full) for this module")])]):(
    _vm.selectedModuleAction.permission === _vm.ModulePermissionTypes.MIXED ||
    (_vm.exceptions &&
      _vm.isExceptionsHaveInstances &&
      _vm.isExceptionsHavePartialPermissions)
  )?_c('div',{class:[_vm.$style.infoContainer, _vm.$style.mixedContainer]},[_c('div',{class:_vm.$style.moduleActionLabel},[_c('i',{class:['mdi mdi-check-circle', _vm.$style.mixedPermissionIcon]}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.moduleActionLabel)}})]),_c('p',[_vm._v(" User has this permission (partial) for this "+_vm._s(_vm.isInstances ? 'instance' : ' module')+" due to: ")]),_c('ul',[(_vm.isRoleHavePartialPermissions)?_c('li',[_vm._v(" some sub-permissions granted through a "),_c('b',[_vm._v("role")])]):_vm._e(),(_vm.isExceptionsHaveForModule)?_c('li',[_c('b',[_vm._v("exceptions")]),_vm._v(" created for a module ")]):_vm._e(),(_vm.isExceptionsHaveInstances)?_c('li',[_c('b',[_vm._v("exceptions")]),_vm._v(" created for some instances ")]):_vm._e(),(_vm.isExceptionsHavePartialPermissions)?_c('li',[_vm._v(" some sub-permissions granted through a "),_c('b',[_vm._v("exceptions")])]):_vm._e()])]):(
    _vm.selectedModuleAction.permission === _vm.ModulePermissionTypes.DENY ||
    (_vm.exceptions &&
      _vm.isExceptionsHaveInstances &&
      !_vm.isExceptionsHaveGrantedPermissions)
  )?_c('div',{class:[_vm.$style.infoContainer, _vm.$style.denyContainer]},[_c('div',{class:_vm.$style.moduleActionLabel},[_c('i',{class:['mdi mdi-close-circle', _vm.$style.denyPermissionIcon]}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.moduleActionLabel)}})]),_c('p',[_vm._v(" User does not have this permission for this "+_vm._s(_vm.isInstances ? 'instance' : ' module')+" due to: ")]),_c('ul',[(_vm.isExceptionsHaveForModule)?_c('li',[_c('b',[_vm._v("exceptions")]),_vm._v(" created for a module ")]):_vm._e(),(_vm.isExceptionsHaveInstances)?_c('li',[_c('b',[_vm._v("exceptions")]),_vm._v(" created for some instances ")]):_vm._e()])]):_c('div',{class:[_vm.$style.infoContainer, , _vm.$style.denyContainer]},[_c('div',{class:_vm.$style.moduleActionLabel},[_c('i',{class:['mdi mdi-close-circle', _vm.$style.denyPermissionIcon]}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.moduleActionLabel)}})]),_c('p',[_vm._v("User does not have this permission for this module.")])])}
var staticRenderFns = []

export { render, staticRenderFns }