






























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseTable from '../../../components/BaseTable.vue';
import { Group } from '../../../store/modules/admin/types/admin.types';
import { ModuleSubdivision } from '../../../store/modules/module-tree/types/module-tree.types';
import {
  Role,
  RoleEntity,
  RoleScopeDto
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import ResourceExceptionTreeComponent from './ResourceExceptionTreeComponent.vue';
import ResourceRoleTreeComponent from './ResourceRoleTreeComponent.vue';

@Component({
  components: {
    BaseTable,
    ResourceRoleTreeComponent,
    ResourceExceptionTreeComponent
  }
})
export default class ViewDetailedAccessConditions extends Vue {
  @Prop() public roles!: Array<{
    role: RoleEntity;
    roleScopes: RoleScopeDto[] | null;
    roleTree: Role[];
  }>;
  @Prop() public exceptions!: Array<{ roleTree: Role; instance?: Group }>;
  @Prop({ type: Boolean, default: false })
  public showParentPermission!: boolean;

  getRoleScopes(roleScopes: RoleScopeDto[] | null): string {
    if (roleScopes && Array.isArray(roleScopes)) {
      if (roleScopes.length > 0) {
        return roleScopes
          .map((scope) =>
            scope.type === 'instance'
              ? (scope.scope as Group).name
              : (scope.scope as ModuleSubdivision).label + ' Groups'
          )
          .join(', ');
      } else {
        return 'All Groups';
      }
    } else {
      return ' ';
    }
  }

  getRolePermissionsInfo(roleScopes: RoleScopeDto[] | null): string {
    if (roleScopes && Array.isArray(roleScopes)) {
      if (roleScopes.length > 0) {
        return ' for some instances';
      } else {
        return ' for all groups';
      }
    } else {
      return ' for a module';
    }
  }
}
