


























import { Component, Vue, Watch } from 'vue-property-decorator';
import BasePaginatorHoc from '../../components/base/BasePaginatorHoc.vue';
import {
  PaginatedResponse,
  PaginatorSpecs,
  SortOrder,
  SortSpecs
} from '@/store/types/general.types';
import BasicSearchAndCreateNew from './components/BasicSearchAndCreateNewComponent.vue';
import RoleList from './components/RoleList.vue';
import ModuleTree from '@/components/ModuleTree.vue';
import RoleModal from '@/views/RolesAndPermissions/components/RoleModal.vue';
import { Action, State } from 'vuex-class';
import {
  GetRolesQueryParams,
  RoleSansTree
} from '@/store/modules/role/types/role.types';
import { RootState } from '@/store/store';

@Component({
  components: {
    ModuleTree,
    BasicSearchAndCreateNew,
    BasePaginatorHoc,
    RoleList
  }
})
export default class RoleTab extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'role';
  public sortOrder: SortOrder = SortOrder.DESC;
  public searchRoleParam: string = '';

  @Action('role/getRoles')
  private getRolesAction!: (
    options: GetRolesQueryParams
  ) => Promise<PaginatedResponse<RoleSansTree>>;

  @State(({ role }: RootState) => role.apiState.deleteRole.success)
  private deleteRoleSuccessState!: boolean;

  get searchRouteQueryParamRole(): string {
    return (this.$route.query.role as string) || '';
  }

  get items(): RoleSansTree[] | undefined {
    return (this.$store.state as RootState).role.roles?.items;
  }

  get totalCount(): number {
    return (this.$store.state as RootState).role.roles?.totalItems ?? 0;
  }

  get RoleList() {
    return RoleList;
  }

  public mounted(): void {
    this.perPage = +this.$route.query.limit || this.perPage;
    this.page = +this.$route.query.page || this.page;
    this.sortColumn =
      (this.$route.query.sortColumn as string) || this.sortColumn;
    this.sortOrder =
      (this.$route.query.sortOrder as SortOrder) || this.sortOrder;
    this.searchRoleParam = this.searchRouteQueryParamRole;
    this.getRoles();
  }

  public handleRefreshRolesList(value: boolean) {
    this.getRoles();
  }

  public openCreateNewRoleModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: RoleModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {
        modalTitle: 'Create New Role'
      },
      events: {
        refreshRolesList: () => {
          this.getRoles();
        }
      }
    });
  }

  public handleSearch(searchInput: string): void {
    if (this.searchRouteQueryParamRole !== searchInput) {
      this.searchRoleParam = searchInput;
      this.getRoles();
    }
  }

  public handlePaginator(paginator: PaginatorSpecs): void {
    this.perPage = paginator.perPage || +this.$route.query.limit;
    this.page = paginator.page || +this.$route.query.page;
    this.getRoles();
  }

  public handleSort(sort: SortSpecs): void {
    this.sortColumn = sort.sortColumn;
    this.sortOrder = sort.sortOrder;
    this.getRoles();
  }

  public getRoles(): void {
    /** set router query params */
    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Roles',
        role: this.searchRoleParam,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder: (this.$route.query.sortOrder as SortOrder) || this.sortOrder
      }
    });

    this.getRolesAction({
      limit: this.perPage,
      page: this.page,
      searchQuery: this.searchRoleParam,
      sortOrder: this.sortOrder
    });
  }

  @Watch('deleteRoleSuccessState')
  private watchDeleteRolesSuccessState() {
    this.getRoles();
  }
}
