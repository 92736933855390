














































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseTable from '../../../components/BaseTable.vue';
import BasePagination from '../../../components/base/BasePagination.vue';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import SortableTableHeader from '../../../components/SortableTableHeader.vue';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { SortOrder } from '@/store/types/general.types';
import { RoleSansTree } from '@/store/modules/role/types/role.types';

@Component({
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    SortableTableHeader
  }
})
export default class RoleList extends mixins(PaginationMixin) {
  @Prop() public items!: RoleSansTree[];
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: SortOrder;
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;

  get roles(): RoleSansTree[] {
    return this.items || [];
  }

  public onClickHeaderColumn(columnName: string): void {
    if (this.sortColumn === columnName) {
      this.$emit('sort', {
        sortColumn: columnName,
        sortOrder:
          this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
      });
    } else {
      this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: SortOrder.DESC
      });
    }
  }

  public openViewRolePermissions(id: number): void {
    this.$router.push(`roles/${id}`);
  }

  public getSortOrderOfColumn(name: string): SortOrder {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return SortOrder.ASC;
  }
}
