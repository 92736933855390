















































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import { Group, User } from '@/store/modules/admin/types/admin.types';
import {
  ModuleExceptionTypes,
  ModuleTreeRecord,
  ResourceTypes,
  Role,
  RolesAndExceptionsResponse,
  PermissionHelperRolesAndExceptionPayload,
  CreateExceptionsPayload,
  ResourceExceptionDto
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import SelectGroupListComponent from './SelectGroupListComponent.vue';
import SelectUserListComponent from './SelectUserListComponent.vue';
import { EntityTypes } from '../../../store/modules/module-tree/enums/module-tree.enums';
import ModuleSearchComponent from './ModuleSearchComponent.vue';
import { ModuleTree } from '../../../store/modules/module-tree/types/module-tree.types';
import ResourceDetailsComponent from './ResourceDetailsComponent.vue';
import AssignExceptionsComponent from './AssignExceptionsComponent.vue';
import { AxiosError } from 'axios';

const initialPayload: CreateExceptionsPayload = {
  resourceType: ResourceTypes.USER,
  resourceId: 0,
  moduleName: '',
  resourceExceptions: []
};

@Component({
  components: {
    SelectUserListComponent,
    SelectGroupListComponent,
    ModuleSearchComponent,
    ResourceDetailsComponent,
    AssignExceptionsComponent
  }
})
export default class CreateExceptionsModal extends Vue {
  @Prop() public moduleTreeRecord!: ModuleTreeRecord;
  public activeStep: number = 0;
  public selectedResource: User | Group | null = null;
  public selectedModule: ModuleTreeRecord | null = null;
  public selectedInstanceType: EntityTypes = EntityTypes.GROUP;
  public selectedInstances: Group[] = [];
  public selectedModuleAction: Role | null = null;
  public createExceptionPayload: CreateExceptionsPayload = initialPayload;
  public moduleExceptions: Role | null = null;
  public resourceInstancesExceptionsDto: Array<{
    moduleExceptions: Role;
    instance?: Group;
  }> = [];

  public allowSameException: boolean = true;
  public isSameExceptionForInstances: boolean = false;
  public userListComponentKey: number = Math.floor(Math.random() * 999);
  public groupListComponentKey: number = Math.floor(Math.random() * 999);
  public moduleSearchComponentKey: number = Math.floor(Math.random() * 999);
  public instanceListComponentKey: number = Math.floor(Math.random() * 999);
  public resourceDetailsComponentKey: number = Math.floor(Math.random() * 999);
  public assignExceptionsComponentKey: number = Math.floor(Math.random() * 999);

  @Action('moduleTree/getModuleTree')
  public getModuleTree!: () => Promise<ModuleTree[]>;

  @State(({ moduleTree }: RootState) => moduleTree.apiState.getModuleTree)
  public getModuleTreeState!: ApiState;

  @State(({ moduleTree }: RootState) => moduleTree.moduleTree)
  public moduleTree!: ModuleTree[];

  @Action('rolesAndPermissions/getResourceInstancesExceptions')
  public getResourceInstancesExceptions!: (resourceId: string) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.getResourceInstancesExceptions
  )
  public getResourceInstancesExceptionsState!: ApiState;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.resourceInstancesExceptions
  )
  public resourceInstancesExceptions!: ResourceExceptionDto[];

  @Action('rolesAndPermissions/createException')
  public createException!: (payload: CreateExceptionsPayload) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.createException
  )
  public createExceptionState!: ApiState;

  @Action('rolesAndPermissions/getRolesAndExceptionsForResource')
  public getRolesAndExceptionsForResource!: (
    payload: PermissionHelperRolesAndExceptionPayload
  ) => Promise<void>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.getRolesAndExceptionsForResource
  )
  public getRolesAndExceptionsForResourceState!: ApiState;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.rolesAndExceptionsForResource
  )
  public rolesAndExceptionsForResource!: RolesAndExceptionsResponse;

  get SelectUserListComponent() {
    return SelectUserListComponent;
  }

  get SelectGroupListComponent() {
    return SelectGroupListComponent;
  }

  get ModuleSearchComponent() {
    return ModuleSearchComponent;
  }

  get AssignExceptionsComponent() {
    return AssignExceptionsComponent;
  }

  get ResourceTypes() {
    return ResourceTypes;
  }

  get resourceType(): ResourceTypes {
    return this.createExceptionPayload.resourceType;
  }

  get isInstanceSelectionAllowed(): boolean {
    return this.selectedModule ? this.selectedModule.hasInstances : false;
  }

  get isButtonDisable(): boolean {
    if (this.activeStep === 0) {
      return (
        !this.createExceptionPayload.resourceType ||
        this.createExceptionPayload.resourceId === 0
      );
    } else if (this.activeStep === 1) {
      return !this.selectedModule;
    }
    return false;
  }

  public getExistingPermissions(instance?: Group): RolesAndExceptionsResponse {
    if (instance) {
      const exceptions = this.rolesAndExceptionsForResource
        ? this.rolesAndExceptionsForResource.exceptions.filter(
            (exception) =>
              exception.instance && exception.instance.id === instance.id
          )
        : [];

      return {
        roles: this.rolesAndExceptionsForResource
          ? this.rolesAndExceptionsForResource.roles
          : [],
        exceptions
      };
    }

    return this.rolesAndExceptionsForResource;
  }

  public hasExistingExceptions(instance?: Group) {
    const permissions = this.getExistingPermissions(instance);
    return permissions && permissions.exceptions.length > 0 ? true : false;
  }

  public closeModal(): void {
    this.$emit('close');
  }

  public nextStep(): void {
    this.activeStep += 1;
    if (this.activeStep > 2) {
      this.assignExceptionsComponentKey += 1;
    }

    if (this.activeStep === 2) {
      const payload: PermissionHelperRolesAndExceptionPayload = {
        resourceType: this.resourceType,
        resourceId: this.createExceptionPayload.resourceId,
        moduleName: this.selectedModule ? this.selectedModule.name : ''
      };
      if (this.selectedInstances && this.selectedInstances.length > 0) {
        payload.instanceIds = this.selectedInstances.map((group) => group.id);
        payload.instanceType = this.selectedInstanceType;
      }
      this.getRolesAndExceptionsForResource(payload);
    }
  }

  public prevStep(): void {
    this.activeStep -= 1;
    if (this.activeStep > 1) {
      this.assignExceptionsComponentKey += 1;
    }
  }

  public handleSelectedResource(resource: User | Group | null) {
    if (resource === null) {
      this.selectedResource = null;
      this.createExceptionPayload.resourceId = 0;
    } else {
      if (this.createExceptionPayload.resourceType === ResourceTypes.USER) {
        this.selectedResource = resource as User;
        this.createExceptionPayload.resourceId = this.selectedResource.userId;
      }
      if (this.createExceptionPayload.resourceType === ResourceTypes.GROUP) {
        this.selectedResource = resource as Group;
        this.createExceptionPayload.resourceId = this.selectedResource.id;
      }

      if (this.moduleTreeRecord) {
        this.selectedModule = null;
        this.resourceInstancesExceptionsDto = [];
        this.handleSelectedModule(this.moduleTreeRecord);
      }

      if (
        this.isInstanceSelectionAllowed &&
        this.selectedInstances &&
        this.selectedInstances.length > 0
      ) {
        const resourceId =
          this.createExceptionPayload.resourceType === ResourceTypes.GROUP
            ? `${EntityTypes.GROUP}_${this.createExceptionPayload.resourceId}`
            : `${this.createExceptionPayload.resourceId}`;
        this.getResourceInstancesExceptions(resourceId);
      }
    }
    this.resourceDetailsComponentKey += 1;
  }

  public handleSelectedModule(module: ModuleTreeRecord | null) {
    if (module === null) {
      this.selectedModule = null;
      this.resourceInstancesExceptionsDto = [];
    } else {
      this.selectedModule = module;
      this.createExceptionPayload.moduleName = module.name;

      if (this.isInstanceSelectionAllowed) {
        const resourceId =
          this.createExceptionPayload.resourceType === ResourceTypes.GROUP
            ? `${EntityTypes.GROUP}_${this.createExceptionPayload.resourceId}`
            : `${this.createExceptionPayload.resourceId}`;
        this.getResourceInstancesExceptions(resourceId);
      } else {
        /**
         * Reset instances selected if user switches to a module that does not
         * have instances.
         */
        this.selectedInstances = [];
      }
      this.resourceInstancesExceptionsDto = [
        {
          moduleExceptions: this.getmoduleExceptions(this.selectedModule.name)
        }
      ];
      this.handleExceptionForInstances();
    }
  }

  public updateExceptionInfo() {
    if (
      this.resourceInstancesExceptions &&
      this.resourceInstancesExceptions.length > 0
    ) {
      const existingInstanceException = this.selectedInstances
        ? this.selectedInstances.some((group) => {
            return this.resourceInstancesExceptions.some((exception) =>
              exception.instanceModule.includes(`${EntityTypes}_${group.id}`)
            );
          })
        : false;
      if (existingInstanceException) {
        this.allowSameException = false;
        this.isSameExceptionForInstances = false;
      } else {
        this.allowSameException = true;
        this.isSameExceptionForInstances = true;
      }
    }
  }

  public handleSelectedInstance(groups: Group[]) {
    this.selectedInstances = groups;
    this.updateExceptionInfo();
    this.handleExceptionForInstances();
  }

  public handleExceptionForInstances() {
    this.resourceInstancesExceptionsDto = [];
    if (this.selectedModule && this.selectedModule.name) {
      const selectedModuleName = this.selectedModule.name;
      if (
        this.selectedInstances &&
        this.selectedInstances.length > 0 &&
        !this.isSameExceptionForInstances
      ) {
        this.resourceInstancesExceptionsDto = this.selectedInstances.map(
          (instance) => ({
            moduleExceptions: this.getmoduleExceptions(selectedModuleName),
            instance
          })
        );
      } else {
        this.resourceInstancesExceptionsDto = [
          {
            moduleExceptions: this.getmoduleExceptions(selectedModuleName)
          }
        ];
      }
    }
  }

  public handleAssignedException(
    module: Role,
    index: number,
    instance?: Group
  ) {
    const exceptionDto: {
      moduleExceptions: Role;
      instance?: Group;
    } = {
      moduleExceptions: module
    };
    if (instance) {
      exceptionDto.instance = instance;
    }
    this.resourceInstancesExceptionsDto.splice(index, 1, exceptionDto);
  }

  public handleCreateException() {
    this.createExceptionPayload.resourceExceptions = [];
    if (
      this.selectedInstances &&
      this.selectedInstances.length > 0 &&
      this.isSameExceptionForInstances
    ) {
      this.selectedInstances.forEach((instance) => {
        const exception = this.resourceInstancesExceptionsDto[0];
        const data: {
          moduleTree: Role;
          instanceType?: EntityTypes;
          instanceId?: number;
        } = {
          moduleTree: exception.moduleExceptions,
          instanceType: this.selectedInstanceType,
          instanceId: instance.id
        };
        this.createExceptionPayload.resourceExceptions.push(data);
      });
    } else {
      this.resourceInstancesExceptionsDto.forEach((exception) => {
        const data: {
          moduleTree: Role;
          instanceType?: EntityTypes;
          instanceId?: number;
        } = {
          moduleTree: exception.moduleExceptions
        };
        if (exception.instance) {
          data.instanceType = this.selectedInstanceType;
          data.instanceId = exception.instance.id;
        }
        this.createExceptionPayload.resourceExceptions.push(data);
      });
    }

    this.createException(this.createExceptionPayload);
  }

  public getExceptionMapAgainstModule(
    moduleExceptions: Role | null,
    moduleTree: ModuleTree[],
    moduleName: string
  ): Role {
    if (!moduleExceptions) {
      for (const branch of moduleTree) {
        if (branch.moduleName === moduleName) {
          moduleExceptions = this.mapExceptions(branch);
          break;
        } else if (branch.submodules.length > 0) {
          moduleExceptions = this.getExceptionMapAgainstModule(
            moduleExceptions,
            branch.submodules,
            moduleName
          );
        }
      }
    }
    return moduleExceptions as Role;
  }

  mapExceptions(moduleTree: any): Role {
    moduleTree.exception = ModuleExceptionTypes.NONE;
    if (
      Array.isArray(moduleTree.submodules) &&
      moduleTree.submodules.length > 0
    ) {
      /**
       * Traverse module tree recursively as modules can have n number of
       * submodules
       */
      for (const submodules of moduleTree.submodules) {
        this.mapExceptions(submodules);
      }
    }

    return moduleTree;
  }

  public mounted(): void {
    this.createExceptionPayload = initialPayload;
    this.getModuleTree();
  }

  @Watch('createExceptionPayload.resourceType')
  public onResourceTypeChange(): void {
    this.createExceptionPayload.resourceId = 0;
    this.resourceDetailsComponentKey += 1;
  }

  public getmoduleExceptions(selectedModuleName: string): Role {
    const moduleExceptions = null;
    return this.getExceptionMapAgainstModule(
      moduleExceptions,
      this.moduleTree,
      selectedModuleName
    );
  }

  @Watch('getResourceInstancesExceptionsState')
  public onGetResourceInstancesExceptionsState(state: ApiState): void {
    if (
      state.success &&
      this.selectedInstances &&
      this.selectedInstances.length > 0
    ) {
      this.updateExceptionInfo();
    }
  }

  @Watch('createExceptionState')
  public onCreateExceptionState(state: ApiState): void {
    if (state.success) {
      const resourceType =
        this.resourceType.charAt(0).toUpperCase() + this.resourceType.slice(1);

      Toast.open({
        queue: true,
        position: 'is-top',
        message: `${resourceType} exception created`,
        type: 'is-dark',
        duration: 5000
      });
      this.$emit('close');
    } else if (state.error) {
      const message =
        (state.error as AxiosError).response?.status === 409
          ? `Conflict: Exception already exists`
          : `Something went wrong. Try again.`;

      Toast.open({
        queue: true,
        position: 'is-top',
        message,
        type: 'is-danger',
        duration: 5000
      });
      this.$emit('close');
    }
    this.$emit('refresh');
  }
}
