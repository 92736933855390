










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class TreeTableRowComponent extends Vue {
  @Prop({ type: Number }) public level!: number;
  @Prop(Boolean) isBorderless!: boolean;
  @Prop(Boolean) isCustomePadding!: boolean;
  @Prop({ type: Boolean, default: true }) showRow!: boolean;
  @Prop({ type: Boolean, default: true }) public noBackground!: boolean;

  public getKey = Math.floor(Math.random() * 999);

  public styleTableObject = {
    padding: this.isCustomePadding
      ? `0.5rem 1rem 0.5rem ${this.level}rem`
      : `1rem`,
    borderBottom: !this.isBorderless ? '1px solid #dfdfdf' : 'none',
    background: this.noBackground ? 'transparent' : '#fbfbfb',
    display: this.showRow ? 'flex' : 'none'
  };
}
