
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ModuleTree from '@/components/ModuleTree.vue';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import {
  ModuleTree as ModuleTreeInterface,
  ModuleTreeState
} from '@/store/modules/module-tree/types/module-tree.types';
import {
  CreateRolePayload,
  Role,
  RoleWithTree,
  UpdateRolePayload
} from '@/store/modules/role/types/role.types';
import {
  ValidationObserver,
  ValidationObserverInstance,
  ValidationProvider
} from 'vee-validate';
import { ToastProgrammatic as Toast } from 'buefy';
import Treeselect from '@riophae/vue-treeselect';

@Component({
  components: { ModuleTree, ValidationProvider, ValidationObserver, Treeselect }
})
export default class RoleModal extends Vue {
  @Prop() modalTitle!: string;
  // Existence of roleId means page is in 'edit' mode
  @Prop() roleId!: number;

  // Type safe access to $refs
  $refs!: {
    moduleTree: ModuleTree;
    roleForm: ValidationObserverInstance;
  };

  @Action('moduleTree/getModuleTree')
  private getModuleTree!: () => Promise<ModuleTree[]>;

  @Action('role/createRole')
  private createRoleAction!: (payload: CreateRolePayload) => void;

  @Action('role/getRoleById')
  private getRoleById!: (id: number) => Promise<RoleWithTree>;

  @Action('role/updateRole')
  private updateRoleAction!: (payload: UpdateRolePayload) => void;

  @Action('role/deleteRole')
  private deleteRoleAction!: (id: number) => void;

  @State(({ role }: RootState) => role.apiState.createRole.success)
  private createRoleSuccessState!: boolean;

  @State(({ role }: RootState) => role.apiState.createRole.error)
  private createRoleErrorState!: string | null;

  @State(({ role }: RootState) => role.apiState.updateRole.success)
  private updateRoleSuccessState!: boolean;

  @State(({ role }: RootState) => role.apiState.updateRole.error)
  private updateRoleErrorState!: string | null;

  @State(({ role }: RootState) => role.apiState.deleteRole.success)
  private deleteRoleSuccessState!: boolean;

  // Form inputs
  private roleName = '';
  private description = '';
  private isAllPermissionsChecked = false;
  private isModuleTreePartiallySelected = false;

  public closeModal(): void {
    this.$emit('close');
  }

  private mounted(): void {
    if (this.roleId) {
      this.getRoleById(this.roleId);
      this.setRoleData();
      this.setAllPermissionsCheckboxValue();
    } else {
      this.getModuleTree();
    }
  }

  // cta = Call to Action
  private get ctaButtonText(): string {
    return this.roleId ? 'Save changes' : 'Create Role';
  }

  private get roleState(): RoleWithTree | undefined {
    return (this.$store.state as RootState).role.role;
  }

  private get moduleTreeState(): ModuleTreeState | undefined {
    return (this.$store.state as RootState)?.moduleTree;
  }

  private get moduleTree(): ModuleTreeInterface[] | undefined {
    // Deep copy done to avoid mutating prop.
    // Either load moduleTree from a role or get module tree array from /module-tree
    if (this.roleState !== undefined && this.roleId) {
      return JSON.parse(JSON.stringify(this.roleState?.roleTree));
    }

    if (this.moduleTreeState?.moduleTree !== undefined && !this.roleId) {
      return JSON.parse(JSON.stringify(this.moduleTreeState?.moduleTree));
    }
  }

  private selectAllPermissions(value: boolean): void {
    this.$refs.moduleTree.selectAllPermissions(value);
  }

  /**
   * State of this checkbox is determined by 3 things:
   * - If any of the modules in the tree is partially selected
   * - If all modules have been checked
   * - If some modules have been checked
   *
   *
   * @private
   */
  private setAllPermissionsCheckboxValue(): void {
    // Return if ModuleTree component is not ready
    if (!this.$refs.moduleTree) {
      return;
    }

    this.isModuleTreePartiallySelected = false;
    const moduleTreeLength = this.moduleTree?.length ?? 0;
    const moduleTreeCheckedCount = this.getModuleTreeCheckedCount(
      this.moduleTree ?? []
    );

    // 'All permissions' is checked if all modules are checked
    if (moduleTreeCheckedCount === moduleTreeLength) {
      this.isAllPermissionsChecked = true;
    }

    if (moduleTreeCheckedCount === 0) {
      this.isAllPermissionsChecked = false;
    }

    /**
     * 'All permissions' is partially checked if any of the modules or their
     * children are partially checked or if all root modules are not checked
     */
    this.isModuleTreePartiallySelected = this.checkIfModuleTreeIsPartiallyChecked(
      this.moduleTree
    );

    if (
      moduleTreeCheckedCount > 0 &&
      moduleTreeCheckedCount < moduleTreeLength
    ) {
      this.isModuleTreePartiallySelected = true;
    }
  }

  private getModuleTreeCheckedCount(moduleTree: ModuleTreeInterface[]): number {
    let count = 0;

    for (const module of moduleTree) {
      if (module.checked) {
        count++;
      }
    }

    return count;
  }

  private checkIfModuleTreeIsPartiallyChecked(
    moduleTree: ModuleTreeInterface[] | undefined
  ): boolean {
    if (!moduleTree) {
      return false;
    }

    for (const module of moduleTree) {
      if (module.partiallyChecked) {
        return true;
      } else {
        this.checkIfModuleTreeIsPartiallyChecked(module.submodules);
      }
    }

    return false;
  }

  private async getFormValidity(): Promise<boolean> {
    const isFormValid = await this.$refs.roleForm.validate();
    if (!isFormValid) {
      return false;
    }

    if (this.isAllPermissionsChecked || this.isModuleTreePartiallySelected) {
      return true;
    }

    Toast.open({
      message: 'Please select at least one module',
      type: 'is-danger',
      position: 'is-top'
    });
    return false;
  }

  private async createRole(): Promise<void> {
    const isFormValid = await this.getFormValidity();

    if (!isFormValid) {
      return;
    }

    this.createRoleAction({
      name: this.roleName,
      description: this.description,
      moduleTree: this.$refs.moduleTree.moduleTree as Role[]
    });
  }

  private async updateRole(): Promise<void> {
    const isFormValid = await this.getFormValidity();

    if (!isFormValid) {
      return;
    }

    this.updateRoleAction({
      id: this.roleId,
      name: this.roleName,
      description: this.description,
      moduleTree: this.$refs.moduleTree.moduleTree as Role[]
    });
  }

  private deleteRole(): void {
    this.deleteRoleAction(this.roleId);
  }

  private runCTA(): void {
    if (this.roleId) {
      this.updateRole();
    } else {
      this.createRole();
    }
  }

  private setRoleData(): void {
    this.roleName = (this.$store.state as RootState).role?.role?.name ?? '';
    this.description =
      (this.$store.state as RootState).role?.role?.description ?? '';
  }

  @Watch('moduleTree')
  private watchModuleTree(): void {
    if (this.roleId) {
      this.setRoleData();
    }
  }

  @Watch('createRoleSuccessState')
  private watchCreateRoleSuccessState(): void {
    if (this.createRoleSuccessState) {
      this.$emit('refreshRolesList');
      Toast.open({
        message: 'Role created',
        type: 'is-success',
        position: 'is-top'
      });
      this.closeModal();
    }
  }

  @Watch('createRoleErrorState')
  private watchCreatRoleErrorState(): void {
    if (this.createRoleErrorState) {
      Toast.open({
        message: this.createRoleErrorState,
        type: 'is-danger',
        position: 'is-top'
      });
    }
  }

  @Watch('updateRoleSuccessState')
  private watchUpdateRoleSuccessState(): void {
    if (this.updateRoleSuccessState) {
      this.$emit('refreshRolesList');
      Toast.open({
        message: 'Role updated',
        type: 'is-success',
        position: 'is-top'
      });
      this.closeModal();
    }
  }

  @Watch('updateRoleErrorState')
  private watchUpdateRoleErrorState(): void {
    if (this.updateRoleErrorState) {
      Toast.open({
        message: this.updateRoleErrorState,
        type: 'is-danger',
        position: 'is-top'
      });
    }
  }

  @Watch('deleteRoleSuccessState')
  private watchDeleteRoleSuccessState(): void {
    this.$emit('deleteRoleEvent');
    Toast.open({
      message: 'Role deleted',
      type: 'is-success',
      position: 'is-top'
    });
    this.closeModal();
  }
}
